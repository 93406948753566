import {get} from '@cullylarson/f'

export const SHIPPING_METHOD_IN_STORE = 'in-store'
export const SHIPPING_METHOD_HOME = 'home'

export const PAYMENT_METHOD_BANDR_ACCOUNT = 'bandr-account'
export const PAYMENT_METHOD_PAYPAL = 'paypal'
export const PAYMENT_METHOD_CREDIT_CARD = 'credit-card'
export const PAYMENT_METHOD_AFFIRM = 'affirm'

// -1 if stepA comes before stepB. 0 if stepA == stepB. 1 if stepA comes after stepB
export const compareSteps = (stepOrder, stepA, stepB) => {
    if(stepA === stepB) return 0

    for(const step of stepOrder) {
        // found stepA first, we know it comes before stepB
        if(step === stepA) return -1

        // found stepB first, we know it comes before stepA
        if(step === stepB) return 1
    }
}

export const getStorePickupPriceFromId = (checkoutStore, storeId) => {
    const willCallStores = get('willCallStores', [], checkoutStore.storePickupOptions.result)
    return get([0, 'willCallFee'], 0, willCallStores.filter(x => x.storeId === storeId))
}

export const getStorePickupNameFromId = (checkoutStore, storeId) => {
    const willCallStores = get('willCallStores', [], checkoutStore.storePickupOptions.result)
    const storeInfo = get(0, null, willCallStores.filter(x => x.storeId === storeId))

    if(!storeInfo) return null

    return storeInfo.name
        ? storeInfo.name
        : `${storeInfo.storeCity}, ${storeInfo.storeState}`
}

export function getOrderTotals(checkoutStore) {
    const checkoutInfo = checkoutStore.info
    const checkoutTokenPayload = checkoutInfo.checkoutTokenPayload

    const orderTotal = checkoutTokenPayload.orderTotal

    const shippingTotal = checkoutInfo.shippingMethod === SHIPPING_METHOD_IN_STORE
        ? (checkoutInfo.pickupStoreEstimateTotal === 0
            ? 0
            : checkoutInfo.pickupStoreEstimateTotal || null)
        : get(['homeShippingEstimateTokenPayload', 'estimateTotal'], null, checkoutInfo)

    const taxTotal = checkoutInfo.salesTaxTokenPayload?.salesTaxTotal || 0

    const giftCardAmount = checkoutInfo.paymentTokenPayload?.giftCardAmount || 0

    const totalToCharge = checkoutInfo.paymentToken
        ? checkoutInfo.paymentTokenPayload.totalToCharge
        : orderTotal + shippingTotal + taxTotal - giftCardAmount

    return {
        orderTotal,
        shippingTotal,
        taxTotal,
        giftCardAmount,
        totalToCharge,
    }
}
