import { combineReducers } from 'redux';
import partType from './part-type/reducer';
import partTypeName from './part-type-name/reducer';
import make from './make/reducer';
import carline from './carline/reducer';
import application from './application/reducer';
import inventory from './inventory/reducer';
export default combineReducers({
  partType: partType,
  partTypeName: partTypeName,
  make: make,
  carline: carline,
  application: application,
  inventory: inventory
});