import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { combineReducers } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';
import { isBrowser } from '@@client/lib/nextjs';
import * as cartRepo from '@@client/cart/cart-repo';
import * as checkoutRepo from '@@client/checkout/checkout-repo';
import config from '@@client/config/reducer';
import notification from '@@client/notification/reducer';
import account from '@@client/account/reducer';
import cart from '@@client/cart/reducer';
import checkout from '@@client/checkout/reducer';
import chat from '@@client/chat/reducer';
import newsletter from '@@client/newsletter/reducer';
import burger from '@@client/burger/reducer';
import parts from '@@client/parts/reducer';
import location from '@@client/location/reducer';
import media from '@@client/media/reducer';
import warranty from '@@client/warranty/reducer';
import message from '@@client/message/reducer';
var combinedReducer = combineReducers({
  config: config,
  notification: notification,
  account: account,
  cart: cart,
  checkout: checkout,
  chat: chat,
  newsletter: newsletter,
  burger: burger,
  parts: parts,
  location: location,
  media: media,
  warranty: warranty,
  message: message
});
export default function rootReducer(state, action) {
  if (action.type === HYDRATE) {
    // don't ever use the server version of the state (action.payload) since it might overwrite something useful on the client.
    // will not be calling redux actions on the server.
    var nextState = state; // inject some values from the browser

    if (isBrowser()) {
      nextState = _objectSpread(_objectSpread({}, nextState), {}, {
        cart: _objectSpread(_objectSpread({}, nextState.cart), cartRepo.getCart()),
        checkout: _objectSpread(_objectSpread({}, nextState.checkout), checkoutRepo.getCheckout())
      });
    }

    return nextState;
  } else {
    return combinedReducer(state, action);
  }
}