const actions = [
    'MARK_GO_TO_CART',
    'CLEAR_GO_TO_CART',
    'CLEAR_SHIPPING_TOKENS',
    'CLEAR_PAYMENT_TOKEN',
    'FILL_BILLING_ADDRESS_FROM_SHIPPING_ADDRESS',

    'INITIALIZE_CHECKOUT_REQUEST',
    'INITIALIZE_CHECKOUT_SUCCESS',
    'INITIALIZE_CHECKOUT_FAILURE',

    'RESET_CHECKOUT',
    'SET_CHECKOUT_FIELD',
    'SET_IS_GUEST',
    'SET_SHIPPING_METHOD',
    'SET_PAYMENT_METHOD',
    'CHOOSE_PICKUP_STORE',

    'CLEAR_CONTACT_INFO_ERRORS',
    'CLEAR_CONTACT_INFO_FAILURE',
    'CONTACT_INFO_FAILURE',

    'STORE_PICKUP_OPTIONS_REQUEST',
    'STORE_PICKUP_OPTIONS_SUCCESS',
    'STORE_PICKUP_OPTIONS_FAILURE',

    'CAN_HOME_SHIP_REQUEST',
    'CAN_HOME_SHIP_SUCCESS',
    'CAN_HOME_SHIP_FAILURE',

    'HOME_SHIPPING_ESTIMATE_REQUEST',
    'HOME_SHIPPING_ESTIMATE_SUCCESS',
    'HOME_SHIPPING_ESTIMATE_FAILURE',

    'STORE_PICKUP_REQUEST',
    'STORE_PICKUP_SUCCESS',
    'STORE_PICKUP_FAILURE',

    'SALES_TAX_REQUEST',
    'SALES_TAX_SUCCESS',
    'SALES_TAX_FAILURE',

    'PAY_WITH_BANDR_ACCOUNT_REQUEST',
    'PAY_WITH_BANDR_ACCOUNT_SUCCESS',
    'PAY_WITH_BANDR_ACCOUNT_FAILURE',

    'PAY_WITH_PAYPAL_REQUEST',
    'PAY_WITH_PAYPAL_SUCCESS',
    'PAY_WITH_PAYPAL_FAILURE',

    'PAY_WITH_AFFIRM_REQUEST',
    'PAY_WITH_AFFIRM_SUCCESS',
    'PAY_WITH_AFFIRM_FAILURE',

    'PAY_WITH_CREDIT_CARD_REQUEST',
    'PAY_WITH_CREDIT_CARD_SUCCESS',
    'PAY_WITH_CREDIT_CARD_FAILURE',

    'GET_CREDIT_CARD_KEY_REQUEST',
    'GET_CREDIT_CARD_KEY_SUCCESS',
    'GET_CREDIT_CARD_KEY_FAILURE',

    'COMPLETE_ORDER_REQUEST',
    'COMPLETE_ORDER_SUCCESS',
    'COMPLETE_ORDER_FAILURE',

    'CHECK_GUEST_EMAIL_REQUEST',
    'CHECK_GUEST_EMAIL_SUCCESS',
    'CHECK_GUEST_EMAIL_FAILURE',
]

const actionTypes = actions.reduce((acc, x) => {
    acc[x] = x
    return acc
}, {})

export default actionTypes
