import urlJoin from 'url-join'
import {getOffset} from '@@client/lib/pagination'
import {paramUrl} from '@@client/lib/url'
import {bearer, responseData, cacheStandardRequest} from '@@client/lib/request'

export const getNewArrivalsList = cacheStandardRequest(600, (tokenCache, apiUrl, {page, perPage, sort, order, goBackDays, partTypeSlug}) => { // 600 seconds == 10 minutes
    const query = {
        sort,
        order,
        num: perPage,
        offset: getOffset(page, perPage),
        goBackDays,
        partTypeSlug,
    }

    const url = paramUrl(urlJoin(apiUrl, '/api/v1/inventory/new-arrivals'), query)

    return tokenCache.get()
        .then(token => fetch(url, {
            method: 'get',
            headers: {
                'Authorization': bearer(token),
            },
        }))
        .then(responseData)
        .then(({response, data}) => {
            if(response.ok) {
                return {
                    success: true,
                    entities: data.entities,
                    numTotal: data.numTotal,
                }
            }
            else {
                return {
                    success: false,
                    errors: data.errors,
                }
            }
        })
})

export const get = cacheStandardRequest(600, (tokenCache, apiUrl, {id}) => { // 600 seconds == 10 minutes
    const url = urlJoin(apiUrl, '/api/v1/inventory', String(id))

    return tokenCache.get()
        .then(token => fetch(url, {
            method: 'get',
            headers: {
                'Authorization': bearer(token),
            },
        }))
        .then(responseData)
        .then(({response, data}) => {
            if(response.ok) {
                return {
                    status: response.status,
                    success: true,
                    entity: data,
                }
            }
            else {
                return {
                    status: response.status,
                    success: false,
                    errors: data.errors,
                }
            }
        })
})

// doesn't have to include everything, but at most will include:
// - part type: three digit number
// - . or -
// - interchange number: up to five characters
// - LR code
// - Alpha extension
const isInventoryNumber = x => {
    // actually 11, but I'm not 100% sure that's the max length
    if(x.length > 12) return false

    // if any whitespace
    if(/\s/.test(x)) return false

    // if it has a period, then it could be followed by letters or numbers
    if(/^[0-9]{3}\.[0-9A-Za-z]{3}/.test(x)) return true

    // otherwise, it must at least have this
    if(/^[0-9]{3}-[0-9]{5}/.test(x)) return true

    return false
}

const isStockTicketNumber = x => /^[A-Za-z]{3}[0-9]{3}/.test(x)

export const search = (tokenCache, apiUrl, {page, perPage, sort, order, filter, ...searchParams}) => {
    // if the search query is likely a hollander/inventory number or a stock ticket number, then search for them directly
    if(searchParams.query) {
        const queryStr = searchParams.query.trim()

        if(isInventoryNumber(queryStr)) {
            searchParams = {
                ...searchParams,
                query: null,
                inventoryNumber: queryStr,
            }
        }
        else if(isStockTicketNumber(queryStr)) {
            searchParams = {
                ...searchParams,
                query: null,
                stockTicketNumber: queryStr,
            }
        }
    }

    const query = {
        ...filter,
        ...searchParams,
        sort,
        order,
        num: perPage,
        offset: getOffset(page, perPage),
    }

    const url = paramUrl(urlJoin(apiUrl, '/api/v1/inventory/search'), query)

    return tokenCache.get()
        .then(token => fetch(url, {
            method: 'get',
            headers: {
                'Authorization': bearer(token),
            },
        }))
        .then(responseData)
        .then(({response, data}) => {
            if(response.ok) {
                return {
                    success: true,
                    entities: data.entities,
                    numTotal: data.numTotal,
                }
            }
            else {
                return {
                    success: false,
                    errors: data.errors,
                }
            }
        })
}
