import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";

var _actionsMap;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { uniqueId } from '@@client/lib/rando';
import * as actionTypes from './action-types';
import * as logoutActionTypes from '@@client/account/logout/action-types';
var initialState = {
  loginIdentifier: null,
  // a value that will change on every successful login/logout (useful to trigger a re-render on login status change)
  doing: false,
  errors: [],
  paramErrors: {}
};
var actionsMap = (_actionsMap = {}, _defineProperty(_actionsMap, logoutActionTypes.LOGOUT_SUCCESS, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    loginIdentifier: uniqueId()
  });
}), _defineProperty(_actionsMap, actionTypes.LOGIN_REQUEST, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    doing: true,
    errors: [],
    paramErrors: {}
  });
}), _defineProperty(_actionsMap, actionTypes.LOGIN_SUCCESS, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    loginIdentifier: uniqueId(),
    doing: false,
    errors: [],
    paramErrors: {}
  });
}), _defineProperty(_actionsMap, actionTypes.LOGIN_FAILURE, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    doing: false,
    errors: action.errors,
    paramErrors: action.paramErrors
  });
}), _defineProperty(_actionsMap, actionTypes.LOGIN_CLEAR, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    // don't reset doing because something might actually be doing
    errors: initialState.errors,
    paramErrors: initialState.paramErrors
  });
}), _actionsMap);
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}