import urlJoin from 'url-join';
import { getOffset } from '@@client/lib/pagination';
import { paramUrl } from '@@client/lib/url';
import { bearer, responseData, cacheStandardRequest } from '@@client/lib/request';
export var list = cacheStandardRequest(1800, function (tokenCache, apiUrl, _ref) {
  var page = _ref.page,
      perPage = _ref.perPage,
      _ref$sort = _ref.sort,
      sort = _ref$sort === void 0 ? 'periodAsDays' : _ref$sort,
      _ref$order = _ref.order,
      order = _ref$order === void 0 ? 'asc' : _ref$order;
  // 1800 seconds == 30 minutes
  var query = {
    order: order,
    num: perPage,
    offset: getOffset(page, perPage)
  };
  var url = paramUrl(urlJoin(apiUrl, '/api/v1/warranty'), query);
  return tokenCache.get().then(function (token) {
    return fetch(url, {
      method: 'get',
      headers: {
        'Authorization': bearer(token)
      }
    });
  }).then(responseData).then(function (_ref2) {
    var response = _ref2.response,
        data = _ref2.data;

    if (response.ok) {
      return {
        success: true,
        entities: data.entities,
        numTotal: data.numTotal
      };
    } else {
      return {
        success: false,
        errors: data.errors
      };
    }
  });
});