import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";

var _actionsMap;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import actionTypes, { l } from './action-types';
var initialState = {
  list: {
    entities: [],
    total: 0,
    doing: false,
    errors: []
  }
};
var actionsMap = (_actionsMap = {}, _defineProperty(_actionsMap, actionTypes["GET_".concat(l, "_LIST_REQUEST")], function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    list: _objectSpread(_objectSpread({}, state.list), {}, {
      doing: true,
      errors: []
    })
  });
}), _defineProperty(_actionsMap, actionTypes["GET_".concat(l, "_LIST_SUCCESS")], function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    list: _objectSpread(_objectSpread({}, state.list), {}, {
      entities: action.entities,
      total: action.numTotal,
      doing: false,
      errors: []
    })
  });
}), _defineProperty(_actionsMap, actionTypes["GET_".concat(l, "_LIST_FAILURE")], function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    list: _objectSpread(_objectSpread({}, state.list), {}, {
      entities: [],
      total: 0,
      doing: false,
      errors: action.errors
    })
  });
}), _actionsMap);
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}