import {compose, filter, headN} from '@cullylarson/f'
import actionTypes from './action-types'
import * as cartRepo from './cart-repo'

// NOTE: All of the cart data was initially stored in the Redux state. However, this created a problem if the site was being used in multiple tabs; one tab could clobber another tab's cart items (e.g. tab 1 adds and item, then tab 2 adds an item; tab 2 won't yet have the addition made by tab 1 and so when it saves, it will overwrite tab 1's changes). To fix, all cart get's and changes are made directly from the cart in localStorage. However, getting rid of all the dispatches presented a problem and I think it may be useful to use these actions in the future, so just dispatching the CART_CHANGED action on all changes.

const excludeItem = (inventoryId, xs) => filter((_, key) => Number(key) !== inventoryId, xs)

function cartChanged() {
    return {
        type: actionTypes.CART_CHANGED,
    }
}

export function clearCart() {
    cartRepo.clearCart()

    return cartChanged()
}

export function addCartItem(partInfo) {
    const cart = cartRepo.getCart()
    cartRepo.saveCart({
        ...cart,
        items: {
            ...cart.items,
            [partInfo.inventoryId]: partInfo,
        },
        itemsLastUpdated: {
            ...cart.itemsLastUpdated,
            [partInfo.inventoryId]: Date.now(),
        },
    })

    return cartChanged()
}

export function updateCartItem(partInfo) {
    const cart = cartRepo.getCart()
    cartRepo.saveCart({
        ...cart,
        items: {
            ...cart.items,
            [partInfo.inventoryId]: partInfo,
        },
        itemsLastUpdated: {
            ...cart.itemsLastUpdated,
            [partInfo.inventoryId]: Date.now(),
        },
    })

    return cartChanged()
}

export function removeCartItem(inventoryId) {
    const cart = cartRepo.getCart()
    cartRepo.saveCart({
        ...cart,
        items: excludeItem(inventoryId, cart.items),
        offers: excludeItem(inventoryId, cart.offers),
        warranties: excludeItem(inventoryId, cart.warranties),
        itemsLastUpdated: excludeItem(inventoryId, cart.itemsLastUpdated),
    })

    return cartChanged()
}

export function addRecentlyViewed(partInfo) {
    const cart = cartRepo.getCart()

    const recentlyViewed = compose(
        // only keep 10 items
        headN(10),
        xs => ([
            // prepend the item
            partInfo,
            ...xs,
        ]),
        // if item is already in the list, remove it so that it can be moved up to the beginning of the list
        filter(x => x.inventoryId !== partInfo.inventoryId),
    )(cart.recentlyViewed)

    cartRepo.saveCart({
        ...cart,
        recentlyViewed,
    })

    return cartChanged()
}

export function makeOfferItem(inventoryId, amount) {
    amount = Number(amount)

    const cart = cartRepo.getCart()
    cartRepo.saveCart({
        ...cart,
        offers: {
            ...cart.offers,
            [inventoryId]: amount,
        },
        // make-offer items can't have warranties
        warranties: excludeItem(inventoryId, cart.warranties),
    })

    return cartChanged()
}

export function selectItemWarranty(inventoryId, warrantyId) {
    const cart = cartRepo.getCart()

    // if this is a make-offer item, don't allow a warranty to be set
    if(cartRepo.isMakeOffer(inventoryId, cart)) return

    cartRepo.saveCart({
        ...cart,
        warranties: {
            ...cart.warranties,
            [inventoryId]: warrantyId,
        },
    })

    return cartChanged()
}

export function removeItemWarranty(inventoryId) {
    const cart = cartRepo.getCart()
    cartRepo.saveCart({
        ...cart,
        warranties: excludeItem(inventoryId, cart.warranties),
    })

    return cartChanged()
}
