import { combineReducers } from 'redux';
import login from './login/reducer';
import logout from './logout/reducer';
import register from './register/reducer';
import verify from './verify/reducer';
import recover from './recover/reducer';
import reset from './reset/reducer';
import order from './order/reducer';
import own from './own/reducer';
export default combineReducers({
  login: login,
  logout: logout,
  register: register,
  verify: verify,
  recover: recover,
  reset: reset,
  order: order,
  own: own
});