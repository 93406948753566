import actionTypes from './action-types'

const initialState = {
    contactUs: {
        doing: false,
        errors: [],
        paramErrors: {},
    },
    sellYourVehicle: {
        doing: false,
        errors: [],
        paramErrors: {},
    },
    hiring: {
        doing: false,
        errors: [],
        paramErrors: {},
    },
}

const actionsMap = {
    [actionTypes.SEND_CONTACT_US_REQUEST]: (state, action) => {
        return {
            ...state,
            contactUs: {
                ...state.contactUs,
                doing: true,
                errors: [],
                paramErrors: {},
            },
        }
    },
    [actionTypes.SEND_CONTACT_US_SUCCESS]: (state, action) => {
        return {
            ...state,
            contactUs: {
                ...state.contactUs,
                doing: false,
            },
        }
    },
    [actionTypes.SEND_CONTACT_US_FAILURE]: (state, action) => {
        return {
            ...state,
            contactUs: {
                ...state.contactUs,
                doing: false,
                errors: action.errors,
                paramErrors: action.paramErrors,
            },
        }
    },

    [actionTypes.SEND_SELL_YOUR_VEHICLE_REQUEST]: (state, action) => {
        return {
            ...state,
            sellYourVehicle: {
                ...state.sellYourVehicle,
                doing: true,
                errors: [],
                paramErrors: {},
            },
        }
    },
    [actionTypes.SEND_SELL_YOUR_VEHICLE_SUCCESS]: (state, action) => {
        return {
            ...state,
            sellYourVehicle: {
                ...state.sellYourVehicle,
                doing: false,
            },
        }
    },
    [actionTypes.SEND_SELL_YOUR_VEHICLE_FAILURE]: (state, action) => {
        return {
            ...state,
            sellYourVehicle: {
                ...state.sellYourVehicle,
                doing: false,
                errors: action.errors,
                paramErrors: action.paramErrors,
            },
        }
    },

    [actionTypes.SEND_HIRING_REQUEST]: (state, action) => {
        return {
            ...state,
            hiring: {
                ...state.hiring,
                doing: true,
                errors: [],
                paramErrors: {},
            },
        }
    },
    [actionTypes.SEND_HIRING_SUCCESS]: (state, action) => {
        return {
            ...state,
            hiring: {
                ...state.hiring,
                doing: false,
            },
        }
    },
    [actionTypes.SEND_HIRING_FAILURE]: (state, action) => {
        return {
            ...state,
            hiring: {
                ...state.hiring,
                doing: false,
                errors: action.errors,
                paramErrors: action.paramErrors,
            },
        }
    },
}

export default function reducer(state = initialState, action = {}) {
    const fn = actionsMap[action.type]
    return fn ? fn(state, action) : state
}
