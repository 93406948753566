import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { pick } from '@cullylarson/f';
import { isServer } from '@@client/lib/nextjs';
import { uniqueId } from '@@client/lib/rando';
import { initialState } from './reducer';
var tabId = uniqueId() + uniqueId();
var storageKey = 'wkr:checkout';
var lastChangedByKey = 'wkr:checkout-last-by';
var propsToStore = ['isGuest', 'info'];
var version = 1; // if the structure of the checkout ever changes, or change propsToStore, increment this value

var checkoutEmpty = _objectSpread(_objectSpread({}, pick(propsToStore, initialState)), {}, {
  version: version
});

export var getCheckout = function getCheckout() {
  if (isServer()) return checkoutEmpty;
  var checkoutRaw = localStorage.getItem(storageKey);
  var checkout = checkoutRaw ? JSON.parse(checkoutRaw) : checkoutEmpty; // we don't want to keep around an old version

  return checkout.version === version ? checkout : checkoutEmpty;
};
export var saveCheckout = function saveCheckout(checkout) {
  if (isServer()) return;

  var checkoutFinal = _objectSpread(_objectSpread(_objectSpread({}, getCheckout()), pick(propsToStore, checkout)), {}, {
    version: version
  });

  localStorage.setItem(storageKey, JSON.stringify(checkoutFinal));
  localStorage.setItem(lastChangedByKey, tabId);
}; // get the id of the browser tab that last changed the checkout state. useful for finding out if we need to reload the checkout state into redux so all tabs are in sync

export var getLastChangedBy = function getLastChangedBy() {
  return isServer() ? undefined : localStorage.getItem(lastChangedByKey);
};