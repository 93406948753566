import { responseData } from '@@client/lib/request';
import { tokenRequest } from '@@client/lib/token-cache';
export function isAvailable(tokenCache, apiUrl) {
  // TODO
  return tokenRequest(tokenCache, function (token) {
    return Promise.resolve({
      json: function json() {
        return Promise.resolve({
          isAvailable: false
        });
      },
      ok: true,
      status: 200
    }).then(responseData).then(function (_ref) {
      var response = _ref.response,
          data = _ref.data;

      if (response.ok) {
        return {
          success: true,
          isAvailable: data.isAvailable
        };
      } else if (response.status === 400) {
        return {
          success: false,
          errors: data.errors.length ? data.errors : ['There were some problems with the values provided. Please see the error messages below.'],
          paramErrors: data.paramErrors
        };
      } else {
        return {
          success: false,
          errors: data.errors,
          paramErrors: []
        };
      }
    });
  });
}