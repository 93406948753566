import * as api from './api';
import * as actionTypes from './action-types';
export function getChatAvailable(tokenCache) {
  return function (dispatch, getState) {
    dispatch(chatAvailableRequest());
    var state = getState();
    api.isAvailable(tokenCache, state.config.chat.apiUrl).then(function (data) {
      if (!data.success) {
        dispatch(chatAvailableFailure(data.errors));
      } else {
        dispatch(chatAvailableSuccess(data.isAvailable));
      }
    })["catch"](function (_) {
      dispatch(chatAvailableFailure(['Something went wrong and we could not determine if chat is available.']));
    });
  };
}

function chatAvailableRequest() {
  return {
    type: actionTypes.CHAT_AVAILABLE_REQUEST
  };
}

function chatAvailableSuccess(isAvailable) {
  return {
    type: actionTypes.CHAT_AVAILABLE_SUCCESS,
    isAvailable: isAvailable
  };
}

function chatAvailableFailure(errors) {
  return {
    type: actionTypes.CHAT_AVAILABLE_FAILURE,
    errors: errors
  };
}