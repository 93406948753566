import urlJoin from 'url-join'
import {compose, map, get} from '@cullylarson/f'
import {paramUrl} from '@@client/lib/url'
import {responseData, cacheStaleWhileRefresh} from '@@client/lib/request'

const defaultCacheSettings = {
    forS: 600, // 600s = 10m
    veryStaleS: 3600, // 3600s = 1h
    retries: 3,
}

const getRequest = cacheStaleWhileRefresh(defaultCacheSettings, (url) => {
    return fetch(url, {
        method: 'get',
    })
        .then(responseData)
})

const getBasicContent = endpoint => apiUrl => {
    const url = urlJoin(apiUrl, endpoint)

    return getRequest(url)
        .then(({response, data}) => {
            if(response.ok) {
                return {
                    success: true,
                    entity: data,
                }
            }
            else {
                return {
                    success: false,
                }
            }
        })
        .catch(() => ({success: false}))
}

export const getHomepageContent = (apiUrl) => {
    const url = urlJoin(apiUrl, 'home-page')

    return getRequest(url)
        .then(({response, data}) => {
            if(response.ok) {
                return {
                    success: true,
                    entity: {
                        ...data,
                        slides: (data.slides || []).map(x => ({
                            ...x,
                            backgroundImage: imageFieldToAbsoluteUrls(apiUrl, x.backgroundImage),
                        })),
                    },
                }
            }
            else {
                return {
                    success: false,
                }
            }
        })
        .catch(() => ({success: false}))
}

export const getBlogArticlesNumTotal = (apiUrl) => {
    const url = urlJoin(apiUrl, 'blog-articles', 'count')

    return getRequest(url)
        .then(({response, data}) => {
            if(response.ok) {
                return {
                    success: true,
                    numTotal: data,
                }
            }
            else {
                return {
                    success: false,
                }
            }
        })
        .catch(() => ({success: false}))
}

// changes relative URLs to absolute in an image field, including all format URLs
const imageFieldToAbsoluteUrls = (apiUrl, imageField) => {
    const makeUrlAbsolute = url => {
        return url[0] === '/'
            // relative url
            ? urlJoin(apiUrl, url)
            // already absolute
            : url
    }

    return {
        ...imageField,
        url: makeUrlAbsolute(imageField.url),
        formats: map(x => ({
            ...x,
            url: makeUrlAbsolute(x.url),
        }), imageField.formats),
    }
}

// strapi uses relative urls for local files, need to make them absolute
const fixRelativeUrls = (apiUrl, str) => {
    apiUrl = apiUrl.replace(/^\//, '') // get rid of leading slash

    const doReplace = (find, replace) => str => str.replace(find, replace)

    return compose(
        // image markdown links
        doReplace(/!\[(.+)\]\(\//, (_, alt) => `![${alt}](${apiUrl}/`),
    )(str)
}

export const getBlogArticleExcerpts = (apiUrl, {offset = 0, num} = {}) => {
    const query = {
        '_limit': num,
        '_start': offset,
        '_sort': 'articleDate:desc',
    }

    const url = paramUrl(urlJoin(apiUrl, 'blog-articles/excerpts'), query)

    return getRequest(url)
        .then(({response, data}) => {
            if(response.ok) {
                return {
                    success: true,
                    entities: map(x => ({
                        ...x,
                        excerpt: fixRelativeUrls(apiUrl, x.excerpt),
                        featuredImage: imageFieldToAbsoluteUrls(apiUrl, x.featuredImage),
                    }), data),
                }
            }
            else {
                return {
                    success: false,
                }
            }
        })
        .catch(() => ({success: false}))
}

export const getBlogArticleBySlug = (apiUrl, slug) => {
    const query = {
        slug,
    }

    const url = paramUrl(urlJoin(apiUrl, 'blog-articles'), query)

    return getRequest(url)
        .then(({response, data}) => {
            if(response.ok) {
                return {
                    success: true,
                    entity: compose(
                        get(0, null),
                        map(x => ({
                            ...x,
                            content: fixRelativeUrls(apiUrl, x.content),
                            excerpt: fixRelativeUrls(apiUrl, x.excerpt),
                            featuredImage: imageFieldToAbsoluteUrls(apiUrl, x.featuredImage),
                        })),
                    )(data),
                }
            }
            else {
                return {
                    success: false,
                }
            }
        })
        .catch(() => ({success: false}))
}

export const getFeaturedPartPageContent = (apiUrl, slug) => {
    const query = {slug}
    const url = paramUrl(urlJoin(apiUrl, 'featured-part-pages'), query)

    return getRequest(url)
        .then(({response, data}) => {
            if(response.ok) {
                const entity = get(0, null, data)

                return entity
                    ? {
                        success: true,
                        entity,
                    }
                    : {
                        success: false,
                    }
            }
            else {
                return {
                    success: false,
                }
            }
        })
        .catch(() => ({success: false}))
}

export const getGeneralContent = getBasicContent('general-content')
export const getAboutPageContent = getBasicContent('about-page')
export const getShippingPageContent = getBasicContent('shipping-and-returns-page')
export const getWarrantiesPageContent = getBasicContent('warranties-page')
export const getPrivacyPolicyPageContent = getBasicContent('privacy-policy-page')
export const getTermsPageContent = getBasicContent('terms-and-conditions-page')
export const getFaqsPageContent = getBasicContent('faqs-page')
export const getReviewsPageContent = getBasicContent('reviews-page')
export const getVideosPageContent = getBasicContent('videos-page')
export const getTaxFormsPageContent = getBasicContent('tax-forms-page')
export const getCutSheetsPageContent = getBasicContent('cut-sheets-page')
export const getPartCategoriesPageContent = getBasicContent('part-categories-page')
export const getVehicleBrandsPageContent = getBasicContent('vehicle-brands-page')
export const getTrimCodesPageContent = getBasicContent('trim-codes-page')
export const getRebuildersInfoPageContent = getBasicContent('rebuilders-info-page')
