export var GET_OWN_REQUEST = 'GET_OWN_REQUEST';
export var GET_OWN_SUCCESS = 'GET_OWN_SUCCESS';
export var GET_OWN_FAILURE = 'GET_OWN_FAILURE';
export var UPDATE_OWN_REQUEST = 'UPDATE_OWN_REQUEST';
export var UPDATE_OWN_SUCCESS = 'UPDATE_OWN_SUCCESS';
export var UPDATE_OWN_FAILURE = 'UPDATE_OWN_FAILURE';
export var UPDATE_OWN_CLEAR = 'UPDATE_OWN_CLEAR';
export var UPDATE_OWN_PASSWORD_REQUEST = 'UPDATE_OWN_PASSWORD_REQUEST';
export var UPDATE_OWN_PASSWORD_SUCCESS = 'UPDATE_OWN_PASSWORD_SUCCESS';
export var UPDATE_OWN_PASSWORD_FAILURE = 'UPDATE_OWN_PASSWORD_FAILURE';
export var UPDATE_OWN_PASSWORD_CLEAR = 'UPDATE_OWN_PASSWORD_CLEAR';