import {set, pick, omit} from '@cullylarson/f'
import {
    getStorePickupPriceFromId,
    getStorePickupNameFromId,
    PAYMENT_METHOD_CREDIT_CARD,
    SHIPPING_METHOD_HOME,
} from './checkout-utils'
import cartActionTypes from '@@client/cart/action-types'
import {getCheckout, getLastChangedBy} from './checkout-repo'
import actionTypes from './action-types'

// NOTE: Some state is fetched from localStorage before being reduced. This is to prevent multiple tabs from clobbering each other. For example, if two tabs are going through the checkout process at the same time (probably won't happen, but would be confusing if it did), one tab could potentially overwrite the changes from another tab, but wouldn't be aware of it until it page was refreshed (because data is in the redux store, but then saved to localStorage on change, but only actually retrieved from localStorage on refresh). So, just fetch state from local storage (see default export at the end of file for how this is done).

const giftCardParamErorsFields = ['giftCardNumber', 'giftCardAmount']
const getGiftCardParamErrors = pick(giftCardParamErorsFields)
const hasGiftCardParamErrors = paramErrors => Object.values(getGiftCardParamErrors(paramErrors)).filter(x => x && x.length > 0).length > 0

export const initialState = {
    isGuest: null,

    loading: false,

    goToCart: false,

    initialize: {
        doing: false,
    },

    contactInfo: {
        errors: [],
        paramErrors: {},
    },

    complete: {
        doing: false,
        errors: [],
        orderNumber: null,
        orderId: null,
    },

    storePickupOptions: {
        doing: false,
        errors: [],
        result: null,
    },

    storePickup: {
        doing: false,
        errors: [],
        paramErrors: {},
    },

    canHomeShip: {
        doing: false,
        errors: [],
        canHomeShip: null,
        willCallOnlyInventoryIds: [],
    },

    homeShippingEstimate: {
        doing: false,
        errors: [],
        paramErrors: {},
    },

    salesTax: {
        doing: false,
        errors: [],
    },

    payWithGiftCard: {
        errors: [],
        paramErrors: {},
    },

    generalPayment: {
        errors: [],
    },

    payWithBandrAccount: {
        doing: false,
        errors: [],
        paramErrors: {},
    },

    payWithPaypal: {
        doing: false,
        errors: [],
        paramErrors: {},
    },

    payWithAffirm: {
        doing: false,
        errors: [],
        paramErrors: {},
    },

    payWithCreditCard: {
        doing: false,
        errors: [],
        paramErrors: {},
    },

    creditCardKey: {
        doing: false,
        errors: [],
        paramErrors: {},
    },

    info: {
        checkoutToken: null,
        checkoutTokenPayload: null,

        homeShippingEstimateToken: null,
        homeShippingEstimateTokenPayload: null,

        storePickupOptionsToken: null,

        storePickupToken: null,
        storePickupTokenPayload: null,

        salesTaxToken: null,
        salesTaxTokenPayload: null,

        paymentToken: null,
        paymentTokenPayload: null,

        // guest contact info
        contactName: null,
        contactEmail: null,
        contactPhone: null,

        shippingMethod: SHIPPING_METHOD_HOME,

        pickupStoreId: null,
        pickupStoreName: null,
        pickupStoreEstimateTotal: null,

        shippingName: null,
        shippingCompany: null,
        shippingAddress1: null,
        shippingAddress2: null,
        shippingCity: null,
        shippingState: null,
        shippingCountry: 'USA',
        shippingZip: null,

        paymentMethod: PAYMENT_METHOD_CREDIT_CARD,

        giftCardNumber: null,
        giftCardAmount: null,

        billingName: null,
        billingAddress1: null,
        billingAddress2: null,
        billingCity: null,
        billingState: null,
        billingCountry: null,
        billingZip: null,
        billingEmail: null,
        billingPhone: null,

        bandrAccountName: null,
        bandrAccountNumber: null,
        bandrAccountPoNumber: null,

        creditCardName: null,
        creditCardExpirationMonth: null,
        creditCardExpirationYear: null,

        creditCardKey: null,
        creditCardKeyPayload: null,

        comments: null,
    },
}

const resetCheckout = (state, preserveOrderNumber = false) => {
    return {
        ...state,
        isGuest: null,
        info: {
            ...state.info,

            checkoutToken: null,
            checkoutTokenPayload: null,

            homeShippingEstimateToken: null,
            homeShippingEstimateTokenPayload: null,

            salesTaxToken: null,
            salesTaxTokenPayload: null,

            storePickupToken: null,
            storePickupTokenPayload: null,

            paymentToken: null,
            paymentTokenPayload: null,

            storePickupOptionsToken: null,

            pickupStoreId: null,
            pickupStoreName: null,
            pickupStoreEstimateTotal: null,

            // clearing this because it's unlikely the user will want to use the same values on multiple orders
            giftCardNumber: null,
            giftCardAmount: null,
            comments: null,

            // clearing this because it's potentially sensitive information
            bandrAccountNumber: null,
            bandrAccountPoNumber: null,

            creditCardKey: null,
            creditCardKeyPayload: null,
        },
        contactInfo: {
            ...state.contactInfo,
            errors: [],
            paramErrors: {},
        },
        storePickupOptions: {
            ...state.storePickupOptions,
            errors: [],
            result: null,
        },
        storePickup: {
            ...state.storePickup,
            errors: [],
            paramErrors: {},
            result: null,
        },
        canHomeShip: {
            ...state.canHomeShip,
            canHomeShip: null,
            willCallOnlyInventoryIds: [],
            errors: [],
        },
        homeShippingEstimate: {
            ...state.homeShippingEstimate,
            errors: [],
            paramErrors: {},
        },
        salesTax: {
            ...state.salesTax,
            errors: [],
        },
        payWithGiftCard: {
            ...state.payWithGiftCard,
            errors: [],
            paramErrors: {},
        },
        generalPayment: {
            ...state.generalPayment,
            errors: [],
        },
        payWithBandrAccount: {
            ...state.payWithBandrAccount,
            errors: [],
            paramErrors: {},
        },
        payWithPaypal: {
            ...state.payWithPaypal,
            errors: [],
            paramErrors: {},
        },
        payWithAffirm: {
            ...state.payWithAffirm,
            errors: [],
            paramErrors: {},
        },
        payWithCreditCard: {
            ...state.payWithCreditCard,
            errors: [],
            paramErrors: {},
        },
        creditCardKey: {
            ...state.creditCardKey,
            errors: [],
            paramErrors: {},
        },
        complete: {
            ...state.complete,
            errors: [],
            orderNumber: preserveOrderNumber ? state.complete.orderNumber : null,
            orderId: preserveOrderNumber ? state.complete.orderId : null,
        },
    }
}

const actionsMap = {
    [cartActionTypes.CART_CHANGED]: (state) => {
        return resetCheckout(state, true)
    },

    [actionTypes.MARK_GO_TO_CART]: (state) => {
        return set('goToCart', true, state)
    },

    [actionTypes.CLEAR_GO_TO_CART]: (state) => {
        return set('goToCart', false, state)
    },

    [actionTypes.SET_CHECKOUT_FIELD]: (state, {fieldName, value}) => {
        return set(['info', fieldName], value, state)
    },

    [actionTypes.FILL_BILLING_ADDRESS_FROM_SHIPPING_ADDRESS]: (state) => {
        return {
            ...state,
            info: {
                ...state.info,
                billingAddress1: state.info.shippingAddress1,
                billingAddress2: state.info.shippingAddress2,
                billingCity: state.info.shippingCity,
                billingState: state.info.shippingState,
                billingCountry: state.info.shippingCountry,
                billingZip: state.info.shippingZip,
            },
        }
    },

    // also need to clear salesTaxToken and paymentToken because they're affected by clearing shipping tokens
    [actionTypes.CLEAR_SHIPPING_TOKENS]: (state) => {
        return {
            ...state,
            info: {
                ...state.info,
                homeShippingEstimateToken: null,
                homeShippingEstimateTokenPayload: null,

                storePickupOptionsToken: null,
                pickupStoreId: null,
                pickupStoreName: null,
                pickupStoreEstimateTotal: null,

                storePickupToken: null,
                storePickupTokenPayload: null,

                salesTaxToken: null,
                salesTaxTokenPayload: null,

                paymentToken: null,
                paymentTokenPayload: null,

                creditCardKey: null,
                creditCardKeyPayload: null,
            },
            storePickupOptions: {
                ...state.storePickupOptions,
                errors: [],
                result: null,
            },
            storePickup: {
                ...state.storePickup,
                errors: [],
                result: null,
            },
        }
    },

    [actionTypes.CLEAR_CONTACT_INFO_ERRORS]: (state) => {
        return {
            ...state,
            contactInfo: {
                ...state.contactInfo,
                errors: [],
                paramErrors: {},
            },
        }
    },

    [actionTypes.CONTACT_INFO_FAILURE]: (state, {paramErrors, errors}) => {
        return {
            ...state,
            contactInfo: {
                ...state.contactInfo,
                errors,
                paramErrors,
            },
        }
    },

    [actionTypes.CHOOSE_PICKUP_STORE]: (state, {storeId}) => {
        const pickupStoreEstimateTotal = getStorePickupPriceFromId(state, storeId)
        const pickupStoreName = getStorePickupNameFromId(state, storeId)

        return {
            ...state,
            info: {
                ...state.info,
                storePickupToken: null,
                storePickupTokenPayload: null,
                pickupStoreId: storeId,
                pickupStoreName,
                pickupStoreEstimateTotal,
            },
        }
    },

    [actionTypes.SET_SHIPPING_METHOD]: (state, {shippingMethod}) => {
        return {
            ...state,
            info: {
                ...state.info,
                shippingMethod,

                storePickupToken: null,
                storePickupTokenPayload: null,

                homeShippingEstimateToken: null,
                homeShippingEstimateTokenPayload: null,

                salesTaxToken: null,
                salesTaxTokenPayload: null,

                paymentToken: null,
                paymentTokenPayload: null,

                creditCardKey: null,
                creditCardKeyPayload: null,
            },
            homeShippingEstimate: {
                ...state.homeShippingEstimate,
                errors: [],
                paramErrors: {},
            },
            storePickup: {
                ...state.storePickup,
                errors: [],
                paramErrors: {},
            },
            salesTax: {
                ...state.salesTax,
                errors: [],
            },
        }
    },

    [actionTypes.SET_PAYMENT_METHOD]: (state, {paymentMethod}) => {
        return {
            ...state,
            info: {
                ...state.info,
                paymentMethod,

                paymentToken: null,
                paymentTokenPayload: null,

                creditCardKey: null,
                creditCardKeyPayload: null,
            },
            payWithGiftCard: {
                ...state.payWithGiftCard,
                errors: [],
                paramErrors: {},
            },
            generalPayment: {
                ...state.generalPayment,
                errors: [],
            },
            payWithBandrAccount: {
                ...state.payWithBandrAccount,
                errors: [],
                paramErrors: {},
            },
            payWithPaypal: {
                ...state.payWithPaypal,
                errors: [],
                paramErrors: {},
            },
            payWithAffirm: {
                ...state.payWithAffirm,
                errors: [],
                paramErrors: {},
            },
            payWithCreditCard: {
                ...state.payWithCreditCard,
                errors: [],
                paramErrors: {},
            },
        }
    },

    [actionTypes.SET_IS_GUEST]: (state, {isGuest}) => {
        return set(['isGuest'], isGuest, state)
    },

    [actionTypes[actionTypes.RESET_CHECKOUT]]: (state, {preserveOrderNumber = false}) => {
        return resetCheckout(state, preserveOrderNumber)
    },

    [actionTypes[actionTypes.INITIALIZE_CHECKOUT_REQUEST]]: (state, action) => {
        return {
            ...state,
            loading: true,
            initialize: {
                ...state.initialize,
                doing: true,
            },
            info: {
                ...state.info,
                checkoutToken: null,
                checkoutTokenPayload: null,
            },
            complete: {
                orderNumber: null,
                orderId: null,
            },
        }
    },
    [actionTypes[actionTypes.INITIALIZE_CHECKOUT_SUCCESS]]: (state, {checkoutToken, checkoutTokenPayload}) => {
        return {
            ...state,
            loading: false,
            info: {
                ...state.info,
                checkoutToken,
                checkoutTokenPayload,
            },
            initialize: {
                ...state.initialize,
                doing: false,
            },
        }
    },
    [actionTypes[actionTypes.INITIALIZE_CHECKOUT_FAILURE]]: (state, action) => {
        return {
            ...state,
            loading: false,
            initialize: {
                ...state.initialize,
                doing: false,
            },
        }
    },

    [actionTypes[actionTypes.SALES_TAX_REQUEST]]: (state, action) => {
        return {
            ...state,
            loading: true,
            salesTax: {
                ...state.salesTax,
                doing: true,
                errors: [],
            },
            info: {
                ...state.info,
                salesTaxToken: null,
                salesTaxTokenPayload: null,
            },
        }
    },
    [actionTypes[actionTypes.SALES_TAX_SUCCESS]]: (state, {salesTaxToken, salesTaxTokenPayload}) => {
        return {
            ...state,
            loading: false,
            info: {
                ...state.info,
                salesTaxToken,
                salesTaxTokenPayload,
            },
            salesTax: {
                ...state.salesTax,
                doing: false,
            },
        }
    },
    [actionTypes[actionTypes.SALES_TAX_FAILURE]]: (state, {errors}) => {
        return {
            ...state,
            loading: false,
            salesTax: {
                ...state.salesTax,
                doing: false,
                errors,
            },
            info: {
                ...state.info,
                // clear this because, even though it's already cleared in SALES_TAX_REQUEST because SALES_TAX_FAILURE might dispatched on the failure of some other kinds of requests
                salesTaxToken: null,
                salesTaxTokenPayload: null,
            },
        }
    },

    [actionTypes[actionTypes.STORE_PICKUP_OPTIONS_REQUEST]]: (state, action) => {
        return {
            ...state,
            loading: true,
            storePickupOptions: {
                ...state.storePickupOptions,
                doing: true,
                errors: [],
                result: null,
            },
            info: {
                ...state.info,
                storePickupOptionsToken: null,
                pickupStoreEstimatePrice: null,
            },
        }
    },
    [actionTypes[actionTypes.STORE_PICKUP_OPTIONS_SUCCESS]]: (state, action) => {
        return {
            ...state,
            loading: false,
            storePickupOptions: {
                ...state.storePickupOptions,
                doing: false,
                result: action.result,
            },
            info: {
                ...state.info,
                storePickupOptionsToken: action.result.storePickupOptionsToken,
            },
        }
    },
    [actionTypes[actionTypes.STORE_PICKUP_OPTIONS_FAILURE]]: (state, {errors}) => {
        return {
            ...state,
            loading: false,
            storePickupOptions: {
                ...state.storePickupOptions,
                doing: false,
                errors,
            },
        }
    },

    [actionTypes[actionTypes.CAN_HOME_SHIP_REQUEST]]: (state, action) => {
        return {
            ...state,
            loading: true,
            canHomeShip: {
                ...state.canHomeShip,
                doing: true,
                errors: [],
                result: null,
            },
        }
    },
    [actionTypes[actionTypes.CAN_HOME_SHIP_SUCCESS]]: (state, action) => {
        return {
            ...state,
            loading: false,
            canHomeShip: {
                ...state.canHomeShip,
                doing: false,
                errors: [],
                canHomeShip: action.canHomeShip,
                willCallOnlyInventoryIds: action.willCallOnlyInventoryIds,
            },
        }
    },
    [actionTypes[actionTypes.CAN_HOME_SHIP_FAILURE]]: (state, {errors}) => {
        return {
            ...state,
            loading: false,
            canHomeShip: {
                ...state.canHomeShip,
                doing: false,
                errors,
                result: null,
            },
        }
    },

    [actionTypes[actionTypes.HOME_SHIPPING_ESTIMATE_REQUEST]]: (state, action) => {
        return {
            ...state,
            loading: true,
            info: {
                ...state.info,
                homeShippingEstimateToken: null,
                homeShippingEstimateTokenPayload: null,

                salesTaxToken: null,
                salesTaxTokenPayload: null,

                storePickupToken: null,
                storePickupTokenPayload: null,

                paymentToken: null,
                paymentTokenPayload: null,

                creditCardKey: null,
                creditCardKeyPayload: null,
            },
            homeShippingEstimate: {
                ...state.homeShippingEstimate,
                doing: true,
                errors: [],
                paramErrors: {},
            },
            complete: {
                ...state.complete,
                errors: [],
            },
        }
    },
    [actionTypes[actionTypes.HOME_SHIPPING_ESTIMATE_SUCCESS]]: (state, {estimateToken, estimateTokenPayload}) => {
        return {
            ...state,
            loading: false,
            info: {
                ...state.info,
                homeShippingEstimateToken: estimateToken,
                homeShippingEstimateTokenPayload: estimateTokenPayload,
            },
            homeShippingEstimate: {
                ...state.homeShippingEstimate,
                doing: false,
            },
        }
    },
    [actionTypes[actionTypes.HOME_SHIPPING_ESTIMATE_FAILURE]]: (state, {errors, paramErrors}) => {
        return {
            ...state,
            loading: false,
            homeShippingEstimate: {
                ...state.homeShippingEstimate,
                doing: false,
                errors,
                paramErrors,
            },
        }
    },

    [actionTypes[actionTypes.STORE_PICKUP_REQUEST]]: (state, action) => {
        return {
            ...state,
            loading: true,
            info: {
                ...state.info,
                storePickupToken: null,
                storePickupTokenPayload: null,

                homeShippingEstimateToken: null,
                homeShippingEstimateTokenPayload: null,

                salesTaxToken: null,
                salesTaxTokenPayload: null,

                paymentToken: null,
                paymentTokenPayload: null,

                creditCardKey: null,
                creditCardKeyPayload: null,
            },
            storePickup: {
                ...state.storePickup,
                doing: true,
                errors: [],
                paramErrors: {},
            },
            complete: {
                ...state.complete,
                errors: [],
            },
        }
    },
    [actionTypes[actionTypes.STORE_PICKUP_SUCCESS]]: (state, {storePickupToken, storePickupTokenPayload}) => {
        return {
            ...state,
            loading: false,
            info: {
                ...state.info,
                storePickupToken,
                storePickupTokenPayload,
            },
            storePickup: {
                ...state.storePickup,
                doing: false,
            },
        }
    },
    [actionTypes[actionTypes.STORE_PICKUP_FAILURE]]: (state, {errors, paramErrors}) => {
        return {
            ...state,
            loading: false,
            storePickup: {
                ...state.storePickup,
                doing: false,
                errors,
                paramErrors,
            },
        }
    },

    [actionTypes[actionTypes.CLEAR_PAYMENT_TOKEN]]: (state, {errors}) => {
        return {
            ...state,
            info: {
                ...state.info,
                paymentToken: null,
                paymentTokenPayload: null,

                creditCardKey: null,
                creditCardKeyPayload: null,
            },
            generalPayment: {
                errors,
            },
        }
    },

    [actionTypes[actionTypes.PAY_WITH_BANDR_ACCOUNT_REQUEST]]: (state, action) => {
        return {
            ...state,
            loading: true,
            info: {
                ...state.info,
                paymentToken: null,
                paymentTokenPayload: null,
            },
            generalPayment: {
                ...state.generalPayment,
                errors: [],
            },
            payWithBandrAccount: {
                ...state.payWithBandrAccount,
                doing: true,
                errors: [],
                paramErrors: {},
            },
            complete: {
                ...state.complete,
                errors: [],
            },
        }
    },
    [actionTypes[actionTypes.PAY_WITH_BANDR_ACCOUNT_SUCCESS]]: (state, {paymentToken, paymentTokenPayload}) => {
        return {
            ...state,
            loading: false,
            info: {
                ...state.info,
                paymentToken,
                paymentTokenPayload,
            },
            payWithBandrAccount: {
                ...state.payWithBandrAccount,
                doing: false,
            },
        }
    },
    [actionTypes[actionTypes.PAY_WITH_BANDR_ACCOUNT_FAILURE]]: (state, {errors, paramErrors}) => {
        return {
            ...state,
            loading: false,
            payWithGiftCard: {
                ...state.payWithGiftCard,
                errors: hasGiftCardParamErrors(paramErrors) ? ['There were some problems with the values provided. Please see the error messages below'] : [],
                paramErrors: getGiftCardParamErrors(paramErrors),
            },
            payWithBandrAccount: {
                ...state.payWithBandrAccount,
                doing: false,
                errors,
                paramErrors,
            },
        }
    },

    [actionTypes[actionTypes.PAY_WITH_PAYPAL_REQUEST]]: (state, action) => {
        return {
            ...state,
            loading: true,
            info: {
                ...state.info,
                paymentToken: null,
                paymentTokenPayload: null,
            },
            generalPayment: {
                ...state.generalPayment,
                errors: [],
            },
            payWithPaypal: {
                ...state.payWithPaypal,
                doing: true,
                errors: [],
                paramErrors: {},
            },
            complete: {
                ...state.complete,
                errors: [],
            },
        }
    },
    [actionTypes[actionTypes.PAY_WITH_PAYPAL_SUCCESS]]: (state, {paymentToken, paymentTokenPayload}) => {
        return {
            ...state,
            loading: false,
            info: {
                ...state.info,
                paymentToken,
                paymentTokenPayload,
            },
            payWithPaypal: {
                ...state.payWithPaypal,
                doing: false,
            },
        }
    },
    [actionTypes[actionTypes.PAY_WITH_PAYPAL_FAILURE]]: (state, {errors, paramErrors}) => {
        return {
            ...state,
            loading: false,
            payWithGiftCard: {
                ...state.payWithGiftCard,
                errors: hasGiftCardParamErrors(paramErrors) ? ['There were some problems with the values provided. Please see the error messages below'] : [],
                paramErrors: getGiftCardParamErrors(paramErrors),
            },
            payWithPaypal: {
                ...state.payWithPaypal,
                doing: false,
                errors,
                paramErrors,
            },
        }
    },

    [actionTypes[actionTypes.PAY_WITH_AFFIRM_REQUEST]]: (state, action) => {
        return {
            ...state,
            loading: true,
            info: {
                ...state.info,
                paymentToken: null,
                paymentTokenPayload: null,
            },
            generalPayment: {
                ...state.generalPayment,
                errors: [],
            },
            payWithAffirm: {
                ...state.payWithAffirm,
                doing: true,
                errors: [],
                paramErrors: {},
            },
            complete: {
                ...state.complete,
                errors: [],
            },
        }
    },
    [actionTypes[actionTypes.PAY_WITH_AFFIRM_SUCCESS]]: (state, {paymentToken, paymentTokenPayload}) => {
        return {
            ...state,
            loading: false,
            info: {
                ...state.info,
                paymentToken,
                paymentTokenPayload,
            },
            payWithAffirm: {
                ...state.payWithAffirm,
                doing: false,
            },
        }
    },
    [actionTypes[actionTypes.PAY_WITH_AFFIRM_FAILURE]]: (state, {errors, paramErrors}) => {
        return {
            ...state,
            loading: false,
            payWithGiftCard: {
                ...state.payWithGiftCard,
                errors: hasGiftCardParamErrors(paramErrors) ? ['There were some problems with the values provided. Please see the error messages below'] : [],
                paramErrors: getGiftCardParamErrors(paramErrors),
            },
            payWithAffirm: {
                ...state.payWithAffirm,
                doing: false,
                errors,
                paramErrors,
            },
        }
    },

    [actionTypes[actionTypes.PAY_WITH_CREDIT_CARD_REQUEST]]: (state, action) => {
        return {
            ...state,
            loading: true,
            info: {
                ...state.info,
                paymentToken: null,
                paymentTokenPayload: null,
            },
            generalPayment: {
                ...state.generalPayment,
                errors: [],
            },
            payWithCreditCard: {
                ...state.payWithCreditCard,
                doing: true,
                errors: [],
                paramErrors: {},
            },
            complete: {
                ...state.complete,
                errors: [],
            },
        }
    },
    [actionTypes[actionTypes.PAY_WITH_CREDIT_CARD_SUCCESS]]: (state, {paymentToken, paymentTokenPayload}) => {
        return {
            ...state,
            loading: false,
            info: {
                ...state.info,
                paymentToken,
                paymentTokenPayload,
            },
            payWithCreditCard: {
                ...state.payWithCreditCard,
                doing: false,
            },
        }
    },
    [actionTypes[actionTypes.PAY_WITH_CREDIT_CARD_FAILURE]]: (state, {errors, paramErrors}) => {
        return {
            ...state,
            loading: false,
            payWithGiftCard: {
                ...state.payWithGiftCard,
                errors: hasGiftCardParamErrors(paramErrors) ? ['There were some problems with the values provided. Please see the error messages below'] : [],
                paramErrors: getGiftCardParamErrors(paramErrors),
            },
            payWithCreditCard: {
                ...state.payWithCreditCard,
                doing: false,
                errors,
                paramErrors,
            },
        }
    },

    [actionTypes[actionTypes.GET_CREDIT_CARD_KEY_REQUEST]]: (state, action) => {
        return {
            ...state,
            loading: true,
            info: {
                ...state.info,
                creditCardKey: null,
                creditCardKeyPayload: null,
            },
            creditCardKey: {
                ...state.creditCardKey,
                doing: true,
                errors: [],
                paramErrors: {},
            },
        }
    },
    [actionTypes[actionTypes.GET_CREDIT_CARD_KEY_SUCCESS]]: (state, {creditCardKey, creditCardKeyPayload}) => {
        return {
            ...state,
            loading: false,
            info: {
                ...state.info,
                creditCardKey,
                creditCardKeyPayload,
            },
            creditCardKey: {
                ...state.creditCardKey,
                doing: false,
            },
        }
    },
    [actionTypes[actionTypes.GET_CREDIT_CARD_KEY_FAILURE]]: (state, {errors, paramErrors}) => {
        return {
            ...state,
            loading: false,
            creditCardKey: {
                ...state.creditCardKey,
                doing: false,
                errors,
                paramErrors,
            },
        }
    },

    [actionTypes[actionTypes.COMPLETE_ORDER_REQUEST]]: (state, action) => {
        return {
            ...state,
            loading: true,
            complete: {
                ...state.complete,
                doing: true,
                errors: [],
                orderNumber: null,
                orderId: null,
            },
        }
    },
    [actionTypes[actionTypes.COMPLETE_ORDER_SUCCESS]]: (state, {orderNumber, orderId}) => {
        return {
            ...state,
            loading: false,
            complete: {
                ...state.complete,
                doing: false,
                orderNumber,
                orderId,
            },
        }
    },
    [actionTypes[actionTypes.COMPLETE_ORDER_FAILURE]]: (state, {errors}) => {
        return {
            ...state,
            loading: false,
            complete: {
                ...state.complete,
                doing: false,
                errors,
            },
        }
    },
}

const reducer = (() => {
    let previousLastChangedBy = null

    return (state = initialState, action = {}) => {
        const fn = actionsMap[action.type]

        // Some state is fetched from localStorage before being reduced. This is to prevent multiple tabs from clobbering each other. For example, if two tabs are going through the checkout process at the same time (probably won't happen, but would be confusing if it did), one tab could potentially overwrite the changes from another tab, but wouldn't be aware of it until it page was refreshed (because data is in the redux store, but then saved to localStorage on change, but only actually retrieved from localStorage on refresh). So, just fetch state from local storage before reducing.
        // However, it would be really expensive to fetch the checkout info from localStorage on *every* redux action. So, only fetch if another tab has changed it.
        const lastChangedBy = getLastChangedBy()

        if(!previousLastChangedBy || previousLastChangedBy !== lastChangedBy) {
            previousLastChangedBy = lastChangedBy

            state = {
                ...state,
                ...omit(['version'], getCheckout()),
            }
        }

        return fn ? fn(state, action) : state
    }
})()

export default reducer
