import 'focus-visible/dist/focus-visible.min.js'
import {useEffect} from 'react'
import {createStore, applyMiddleware, compose as reduxCompose} from 'redux'
import {useStore} from 'react-redux'
import thunk from 'redux-thunk'
import {useRouter} from 'next/router'
import {createWrapper} from 'next-redux-wrapper'
import rootReducer from '@@client/root-reducer'
import {getClientConfig, getServerConfig} from '@@client/config'
import {isDev} from '@@client/lib/util'
import {isEmptyObject} from '@@client/lib/objs'
import {isBrowser, isServer} from '@@client/lib/nextjs'
import {sendEvent} from '@@client/lib/gtm'
import TokenCache from '@@client/lib/token-cache'
import DepsContext from '@@client/app/DepsContext'
import {getChatAvailable} from '@@client/chat/actions'
import {getWarrantyList} from '@@client/warranty/actions'
import * as checkoutRepo from '@@client/checkout/checkout-repo'
import {useInterval} from '@@client/lib/hooks'
import {getCart, getItemNames, getOldItems} from '@@client/cart/cart-repo'
import * as inventoryApi from '@@client/parts/inventory/api'
import {removeCartItem, updateCartItem} from '@@client/cart/actions'
import {addNotificationNotice} from '@@client/notification/actions'
import {getGeneralContent} from '@@client/content/api'

import '@@client/style/global.css'

// eslint-disable-next-line no-unused-expressions
if(isDev()) import('@@client/style/debug.css')

const fifteenMinutes = 900000 // 900000ms == 15m

const appInitialState = {
    config: getClientConfig(),
    // everything else will be set by the reducers
}

const composeEnhancers = isDev() && isBrowser()
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || reduxCompose
    : reduxCompose

const makeStore = () => {
    const CheckoutStoreListener = () => {
        let currentCheckout

        return () => {
            const state = store.getState()
            const previousCheckout = currentCheckout
            currentCheckout = state.checkout

            // changed
            if(previousCheckout !== currentCheckout) {
                checkoutRepo.saveCheckout(currentCheckout)
            }
        }
    }

    const store = createStore(
        rootReducer,
        appInitialState,
        composeEnhancers(
            applyMiddleware(thunk),
        ),
    )

    // save checkout if it changes
    store.subscribe(CheckoutStoreListener())

    return store
}

const wrapper = createWrapper(makeStore)

const App = ({Component, deps = {}, pageProps, ...rest}) => {
    const router = useRouter()
    const store = useStore()

    deps = {
        ...deps,
        // if tokenCache was built on the server, will not get passed to client
        tokenCache: deps.tokenCache && !isEmptyObject(deps.tokenCache)
            ? deps.tokenCache
            : TokenCache(store),
    }

    useEffect(() => {
        store.dispatch(getWarrantyList(deps.tokenCache))
        store.dispatch(getChatAvailable(deps.tokenCache))
    }, [])

    useEffect(() => {
        const doIt = (url) => {
            // the window globals used here should be defined in Layout

            // facebook pixel
            if(window.fbq) {
                window.fbq('track', 'PageView')
            }

            // google tag manager
            sendEvent('pageview')
        }

        router.events.on('routeChangeComplete', doIt)

        return () => router.events.off('routeChangeComplete', doIt)
    }, [])

    // update any inventory items in the cart that need it
    const checkInventoryItems = async () => {
        const oldItems = getOldItems()

        await Promise.all(oldItems.map(async (item) => {
            try {
                const result = await inventoryApi.get(deps.tokenCache, appInitialState.config.inventory.apiUrl, {id: item.inventoryId})

                const cart = getCart()

                if(result.status === 404) {
                    store.dispatch(addNotificationNotice(<span>One of your items is no longer in our inventory. It has been removed from your cart: <strong>{getItemNames(cart, [item.inventoryId]).join(' ')}</strong></span>))
                    store.dispatch(removeCartItem(item.inventoryId))
                }
                else if(result.success) {
                    store.dispatch(updateCartItem(result.entity))
                }
            }
            catch(err) {
                // Do nothing. If something goes wrong, we don't want to remove
                // the item (it might still exist). So we can't really do
                // anything.
            }
        }))
    }

    useInterval(checkInventoryItems, fifteenMinutes, {runOnStartup: true})

    return (
        <DepsContext.Provider value={deps}>
            <Component {...pageProps} />
        </DepsContext.Provider>
    )
}

async function getServerSideStuff() {
    const config = getServerConfig()

    const result = await getGeneralContent(config.content.apiUrl)

    return {
        generalContent: result.success ? result.entity : {failed: true},
    }
}

App.getInitialProps = async ({Component, ctx}) => {
    // will inject this into ctx, so Component.getInitialProps has accesss to it.
    const deps = {
        tokenCache: TokenCache(ctx.store, ctx),
        ...(isServer() ? await getServerSideStuff() : {}),
    }

    const pageProps = Component.getInitialProps
        ? await Component.getInitialProps({...ctx, deps})
        : {}

    return {pageProps, deps}
}

const ExportApp = wrapper.withRedux(App)

export default ExportApp
