const actions = [
    'SEND_CONTACT_US_REQUEST',
    'SEND_CONTACT_US_SUCCESS',
    'SEND_CONTACT_US_FAILURE',

    'SEND_SELL_YOUR_VEHICLE_REQUEST',
    'SEND_SELL_YOUR_VEHICLE_SUCCESS',
    'SEND_SELL_YOUR_VEHICLE_FAILURE',

    'SEND_HIRING_REQUEST',
    'SEND_HIRING_SUCCESS',
    'SEND_HIRING_FAILURE',
]

const actionTypes = actions.reduce((acc, x) => {
    acc[x] = x
    return acc
}, {})

export default actionTypes
