import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";

var _actionsMap;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { uniqueId } from '@@client/lib/rando';
import * as actionTypes from './action-types';
var initialState = {
  notifications: []
};
var actionsMap = (_actionsMap = {}, _defineProperty(_actionsMap, actionTypes.ADD_NOTIFICATION, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    notifications: [].concat(_toConsumableArray(state.notifications), [{
      id: uniqueId(),
      msg: action.msg,
      kind: action.kind,
      options: action.options,
      isShown: false
    }])
  });
}), _defineProperty(_actionsMap, actionTypes.MARK_NOTIFICATION_SHOWN, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    notifications: state.notifications.map(function (x) {
      return x.id === action.id ? _objectSpread(_objectSpread({}, x), {}, {
        isShown: true
      }) : x;
    })
  });
}), _defineProperty(_actionsMap, actionTypes.REMOVE_NOTIFICATION, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    notifications: state.notifications.filter(function (x) {
      return x.id !== action.id;
    })
  });
}), _actionsMap);
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}