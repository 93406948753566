import * as api from './api';
import actionTypes, { l } from './action-types';
var text = {
  singular: 'warranty',
  plural: 'warranties'
};

function getListRequest() {
  return {
    type: actionTypes["GET_".concat(l, "_LIST_REQUEST")]
  };
}

function getListSuccess(entities, numTotal) {
  return {
    type: actionTypes["GET_".concat(l, "_LIST_SUCCESS")],
    entities: entities,
    numTotal: numTotal
  };
}

function getListFailure(errors) {
  return {
    type: actionTypes["GET_".concat(l, "_LIST_FAILURE")],
    errors: errors
  };
}

export function getWarrantyList(tokenCache) {
  var page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var perPage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : -1;
  return function (dispatch, getState) {
    var state = getState();
    dispatch(getListRequest());
    api.list(tokenCache, state.config.orders.apiUrl, {
      page: page,
      perPage: perPage
    }).then(function (data) {
      if (data.errors) dispatch(getListFailure(data.errors));else dispatch(getListSuccess(data.entities, data.numTotal));
    })["catch"](function (_) {
      return dispatch(getListFailure(["Something went wrong and ".concat(text.plural, " could not be fetched. Please try again.")]));
    });
  };
}